import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Terms from '../terms/style'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
export default function TermsWL(){
return(
<Terms>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
        <Terms.Box>
          <Terms.Title as="h3">We-Connect White Label - Terms of Service</Terms.Title>
        </Terms.Box>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="col-xxl-8 col-xl-9 col-lg-10">
        <Terms.Content>
          
          <Terms.ContentBox>
            
            <Terms.Text>“You”, “Your” and “Customer” means the person, entity or company who is subscribing to the Platform described in this Agreement. “We”, “Our”, “Us” and “We-Connect” means We-Connect. This We-Connect White Label Service Agreement (the “Agreement”) is entered into as of the date of acceptance by Customer as recorded in the subscription process (the “Effective Date”) is by and between We-Connect and Customer. RECITALS A. We-Connect has developed a proprietary online platform that allows white label partners to use for themselves and offer to their customers (hereinafter referred as end users) the ability to automate certain tasks on LinkedIn and B. Customer wishes to license the Platform on the terms and conditions herein. NOW, THEREFORE, acknowledging the receipt of adequate consideration and intending to be legally bound, the parties agree as follows: 1. Grant of License. a. Limited License. We-Connect grants to Customer a limited, non-exclusive, non transferable, non-sublicensable license to use the Platform during the Term solely to manage customer’s business relying on Linkedin automation. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">White Label Branding.</Terms.TitleSmall>
            <Terms.Text>All branding will be in the customer's name and shall be accessible to the public under a URL designated by Customer (i.e. www.yourdomainname.com). The customer’s name, trademark, trade name, designs and logos shall appear on Customer URL.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Services Description.</Terms.TitleSmall>
            <Terms.Text>All modules of We-Connect shall be available to Customer except the help section. We-Connect reserves the rights to change the appearance and functionality of these modules at We-Connect’s own discretion without advance prior notification to the Customer. b. We-Connect will host and maintain the platform as part of this service agreement.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Subscriptions</Terms.TitleSmall>
            <Terms.Text>All services are billed on a recurring subscription basis ("Subscription(s)"). You will be billed and charged in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set on a monthly basis.</Terms.Text>
            <Terms.Text>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or We-Connect cancels it. You may cancel your Subscription renewal by cancelling your recurring payment.</Terms.Text>
            <Terms.Text>A valid payment method is required to process the payment for your Subscription. You shall provide accurate and complete billing information and valid payment method information. By submitting such payment information, you automatically authorize We-Connect to charge all Subscription fees incurred through your account to any such payment instruments.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Subscription Fees.</Terms.TitleSmall>
            <Terms.Text>The subscription fees or pricing for the use of We-Connect’s Platform are displayed under the billing section of the platform.</Terms.Text>
            
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Subscription Fee Changes</Terms.TitleSmall>
            <Terms.Text>We-Connect, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</Terms.Text>
            <Terms.Text>We-Connect will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</Terms.Text>
            <Terms.Text>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</Terms.Text>
          
          <Terms.Text>Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Refunds</Terms.TitleSmall>
            <Terms.Text>Except when required by law, paid Subscription fees are non-refundable.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">End-user pricing:</Terms.TitleSmall>
            <Terms.Text>The whitelabel currently can support only one type of pricing plan. You can not offer multiple pricing plans to your end-users.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Non-Compete:</Terms.TitleSmall>
            <Terms.Text>You agree that you will not directly or indirectly compete with We-Connect. We will not directly solicit or contact and or enter into any type of business relationship with your end-users. At the same time you understand that we will continue to promote its business and in the normal course and agree that your end-users may directly subscribe to we-connect's platform and may terminate their relationship with you.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Customer support:</Terms.TitleSmall>
            <Terms.Text>We-Connect will provide customer support to you via Intercom live chat from 8.00 AM to 5.00 PM, EST, Weekdays only for the matters related to the performance of the platform. You shall be responsible for providing any customer support to your end users.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Accounts</Terms.TitleSmall>
            <Terms.Text>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</Terms.Text>
            <Terms.Text>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</Terms.Text>
            <Terms.Text>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</Terms.Text>
            <Terms.Text>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene. You expressly agree that we cannot be held liable for any loss or damage arising out of any misrepresentations you make in this regard.</Terms.Text>
            <Terms.Text>You agree not to attempt to hack, reverse engineer or damage We-Connect or any of the services We-Connect offers in any way.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Intellectual Property</Terms.TitleSmall>
            <Terms.Text>The Service and its original content, features and functionality are and will remain the exclusive property of We-Connect and its licensors.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Connecting to Linkedin</Terms.TitleSmall>
            <Terms.Text>Our Service connects to Linkedin services that are not owned or controlled by We-Connect.</Terms.Text>
            <Terms.Text>We-Connect has no control over, and assumes no responsibility for, the content, privacy policies, terms of use, or practices of LinkedIn. You further acknowledge and agree that We-Connect shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through “We-Connect”s website and it services.
</Terms.Text>
            <Terms.Text>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.</Terms.Text>

          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Termination</Terms.TitleSmall>
            <Terms.Text>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</Terms.Text>
            <Terms.Text>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
</Terms.Text>
            <Terms.Text>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</Terms.Text>

          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Indemnification</Terms.TitleSmall>
            <Terms.Text>You agree to defend, indemnify and hold harmless We-Connect and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Limitation Of Liability</Terms.TitleSmall>
            <Terms.Text>We-Connect is not responsible for and will not assume responsibility for server downtime, server delayed response time, network issues, or any other issues caused by interruption or intermittent issues of the underlying hosting service provider or by changes to LinkedIn’s code or user interface.</Terms.Text>
            <Terms.Text>In no event shall We-Connect, nor its principals, directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</Terms.Text>
          
          <Terms.Text>We-Connect is in no way affiliated or connected with LinkedIn. By using www.We-Connect.io, you agree that We-Connect holds no responsibility for any losses or liabilities incurred, including the imposition of restrictions, penalties by LinkedIn.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Disclaimer</Terms.TitleSmall>
            <Terms.Text>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</Terms.Text>
          
          <Terms.Text>We-Connect its principals, subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Exclusions</Terms.TitleSmall>
            <Terms.Text>Without limiting the generality of the foregoing and notwithstanding any other provision of these terms, under no circumstances will We-Connect ever be liable to you or any other person for any indirect, incidental, consequential, special, punitive or exemplary loss or damage arising from, connected with, or relating to your use of the Service, these Terms, the subject matter of these Terms, the termination of these Terms or otherwise, including but not limited to personal injury, loss of data, business, markets, savings, income, profits, use, production, reputation or goodwill, anticipated or otherwise, or economic loss, under any theory of liability (whether in contract, tort, strict liability or any other theory or law or equity), regardless of any negligence or other fault or wrongdoing (including without limitation gross negligence and fundamental breach) by We-Connect or any person for whom We-Connect is responsible, and even if We-Connect has been advised of the possibility of such loss or damage being incurred.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Exclusivity:</Terms.TitleSmall>
            <Terms.Text>You acknowledge that this Agreement is not an exclusive arrangement and shall not limit or restrict us in any manner, including without limitation limiting us in marketing, hosting, recommending, referring or selling products that are competitive to your products, or entering into similar agreements with third parties</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Governing Law</Terms.TitleSmall>
            <Terms.Text>These Terms shall be governed and construed in accordance with the laws of United States of America, without regard to its conflict of law provisions.</Terms.Text>
            <Terms.Text>You are responsible for making sure you and your use of We-Connect are in compliance with any local laws and the laws of the country you are accessing and/or using the services in and/or from. The use of We-Connect is not intended for engaging in spam or harassing behavior. You agree not to use We-Connect to engage in a spamming behavior. Do not use this service for any illegal activities or for offering or facilitating any illegal activities.
</Terms.Text>
            <Terms.Text>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</Terms.Text>

          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Assignment:</Terms.TitleSmall>
            <Terms.Text>We may assign this Agreement to another entity without any advance consent from or notice to you. You may not assign this Agreement without our prior written consent.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Changes</Terms.TitleSmall>
            <Terms.Text>We-Connect reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</Terms.Text>
          
          <Terms.Text>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you must stop using the service.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Privacy Policy</Terms.TitleSmall>
            <Terms.Text>Please refer to our Privacy Policy. You agree that they constitute part of these terms. You must read our <AnchorLink to="/privacy">Privacy Policy</AnchorLink> before you use the Service.</Terms.Text>
          </Terms.ContentBox>
          <Terms.ContentBox>
            <Terms.TitleSmall as="h3">Contact Us</Terms.TitleSmall>
            <Terms.Text>If you have any questions about these Terms, please contact us at<a href="mailto: support@we-connect.io">support@we-connect.io</a></Terms.Text>
          </Terms.ContentBox>
        </Terms.Content>
      </Col>
    </Row>
  </Container>
</Terms>

)
}