import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/HeaderWL'
//import { Link } from "gatsby";
import TermsWL from "~sections/WL-terms/TermsWL"
import FooterWL from "~sections/marketing/FooterWL/FooterWL"
import CtaWL from "~sections/marketing/CtaWL/CtaWL"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="Apply now"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function WLTerms() {
  return (
    <PageWrapper headerConfig={header}>
      <TermsWL />
      <CtaWL />
      <FooterWL />
    </PageWrapper>
  )
}
